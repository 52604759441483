<!--
 * @Description: 首页页面
 * @Author: huacong
 * @Date: 2021-04-27 14:00:21
 * @LastEditTime: 2021-09-13 16:08:47
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <el-image :src="topbanner" style="margin-top:-24px"></el-image>
    <section>
      <!-- 搜索框 -->
      <div class="searchBox">
        <span class="searchInput">
          <el-input
            placeholder="输入职位关键词，如“一级建造师”等"
            class="input-with-select"
            v-model="searchCer"
          >
            <el-select
              v-model="selectWorkType"
              slot="prepend"
              class="input_select"
            >
              <el-option label="找兼职" value="找兼职"></el-option>
              <el-option label="找全职" value="找全职"></el-option>
            </el-select>
            <!-- icon="el-icon-search" -->
            <el-button
              slot="append"
              type="primary"
              v-prevent-repeat-click
              @click="searchPost"
            >
              <img
                class="mini-icon"
                :src="searchIcon"
                alt=""
                style="width: 15px;top: 0;"
              />
              <span class="mini-icon" style="top: -2px;">搜索</span>
            </el-button>
          </el-input>
        </span>
        <el-button
          type="primary"
          class="radius-25"
          style="width:120px;margin-left:16px;"
          v-prevent-repeat-click
          @click="postJobBtn"
          v-if="userInfo.u_identity != 1"
          >发布职位</el-button
        >
        <p style="margin: 10px 44px;">
          <span style="color: #999">热门岗位：</span>
          <span class="hotcer">
            <el-button
              type="text"
              class="cerBtn fontBlod"
              @click="goPartTimePage('一级建造师', '')"
            >
              一级建造师
            </el-button>
            <el-button
              type="text"
              class="cerBtn fontBlod"
              @click="goPartTimePage('二级建造师', '')"
            >
              二级建造师
            </el-button>
            <el-button
              type="text"
              class="cerBtn fontBlod"
              @click="goPartTimePage('职称证书', '')"
            >
              职称证书
            </el-button>
            <el-button
              type="text"
              class="cerBtn fontBlod"
              @click="goPartTimePage('注册监理工程师', '')"
            >
              注册监理工程师
            </el-button>
          </span>
        </p>
      </div>
      <!-- 证书导航 -->
      <workNav></workNav>
      <!-- 热门资质 -->
      <div class="hotComBox">
        <el-tabs v-model="hotComActive">
          <el-tab-pane label="热门资质" name="first">
            <el-row :gutter="24" class="hotComList">
              <el-col
                :span="8"
                v-for="item in hotzizhiList"
                :key="item.id"
                class="margin-bottom-16"
              >
                <div class="hotComItem" @click="gozizhiDetail(item.id)">
                  <div>
                    <img class="zizhiImg" :src="zizhiImg" alt="Logo" />
                    <div class="comInfo">
                      <span class="fontHover ziZhiTitle">{{ item.title }}</span>
                      <p>
                        <span>
                          {{ item.category }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="hotcomPost">
                    <span style="color:#FF6F24;"
                      >{{ item.province }}-{{ item.city }}</span
                    >
                    <img :src="hotPng" alt="" style="float: right;" />
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <div style="padding:20px" class="text-center">
          <el-button
            class="radius-25 bigBtn"
            type="primary"
            v-prevent-repeat-click
            @click="goaptList"
          >
            查看更多
          </el-button>
        </div>
      </div>
      <!-- 热门职位 -->
      <div class="hotComBox">
        <el-tabs v-model="hotComActive">
          <el-tab-pane label="热门职位" name="first">
            <el-row :gutter="24" class="hotWorkList">
              <el-col
                :span="8"
                v-for="item in hotWorkList"
                :key="item.id"
                class="margin-bottom-16"
              >
                <div class="hotWorkItem">
                  <img class="commendIcon" :src="commendIcon" />
                  <span class="commendFont">推荐</span>
                  <div style="padding: 20px">
                    <div style="padding-bottom: 10px">
                      <span class="workName fontHover" @click="gopartTime(item)"
                        >{{ item.j_certificate }}--{{ item.j_major }}</span
                      >
                      <span class="workTime">{{ item.create_time }}</span>
                    </div>
                    <div class="workComInfo">
                      <span class="wageNum">
                        {{ item.j_min_price * 1 }}万-{{
                          item.j_max_price * 1
                        }}万
                      </span>
                      <span>
                        {{ item.j_register }}/{{ item.j_social_need }}
                      </span>
                    </div>
                  </div>
                  <div class="hotworkPost">
                    <img
                      class="hotcomImg"
                      :src="item.company_portrait"
                      alt=""
                    />
                    <div class="workCom">
                      <span
                        class="hotworkComName fontHover"
                        @click="goComDetail2(item)"
                        >{{ item.com_external_name }}</span
                      >
                      <el-button
                        size="mini"
                        round
                        v-prevent-repeat-click
                        class="float-right touBtn"
                        style="margin-top: -6px;"
                        @click="deliver(item)"
                      >
                        投一个呗
                      </el-button>
                      <p>
                        <span>{{ item.com_province }}-{{ item.com_city }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <div style="padding:20px" class="text-center">
          <el-button
            class="radius-25 bigBtn"
            type="primary"
            v-prevent-repeat-click
            @click="goPartTimeIndex"
          >
            查看更多
          </el-button>
        </div>
      </div>
      <!-- 最新职位 -->
      <div class="hotComBox">
        <el-tabs v-model="hotComActive">
          <el-tab-pane label="最新职位" name="first">
            <el-row :gutter="24" class="hotWorkList">
              <el-col
                :span="8"
                v-for="item in newWorkList"
                :key="item.id"
                class="margin-bottom-16"
              >
                <div class="hotWorkItem">
                  <!-- <img class="commendIcon" :src="commendIcon" /> -->
                  <!-- <span class="commendFont">推荐</span> -->
                  <div style="padding: 20px">
                    <div style="padding-bottom: 10px">
                      <span class="workName fontHover" @click="gopartTime(item)"
                        >{{ item.j_certificate }}--{{ item.j_major }}</span
                      >
                      <span class="workTime">{{ item.create_time }}</span>
                    </div>
                    <div class="workComInfo">
                      <span class="wageNum">
                        {{ item.j_min_price * 1 }}万-{{
                          item.j_max_price * 1
                        }}万
                      </span>
                      <span>
                        {{ item.j_register }}/{{ item.j_social_need }}
                      </span>
                    </div>
                  </div>
                  <div class="hotworkPost">
                    <img class="hotcomImg" :src="item.u_portrait" alt="" />
                    <div class="workCom">
                      <span
                        class="hotworkComName fontHover"
                        @click="goComDetail2(item)"
                        >{{ item.com_external_name }}</span
                      >
                      <el-button
                        size="mini"
                        round
                        v-prevent-repeat-click
                        class="float-right touBtn"
                        style="margin-top: -6px;"
                        @click="deliver(item)"
                      >
                        投一个呗
                      </el-button>
                      <p>
                        <span
                          >{{ item.j_area_province }}-{{
                            item.j_area_city
                          }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <div style="padding:20px" class="text-center">
          <el-button
            class="radius-25 bigBtn"
            type="primary"
            v-prevent-repeat-click
            @click="goPartTimeIndex"
          >
            查看更多
          </el-button>
        </div>
      </div>
      <!-- 热门企业 -->
      <div class="hotComBox">
        <el-tabs v-model="hotComActive">
          <el-tab-pane label="热门公司" name="first">
            <el-row :gutter="24" class="hotComList">
              <el-col
                :span="8"
                v-for="item in hotComList"
                :key="item.id"
                class="margin-bottom-16"
              >
                <div class="hotComItem" @click="goComDetail(item)">
                  <div>
                    <img
                      class="hotcomImg"
                      :src="item.company_portrait"
                      alt="Logo"
                    />
                    <div class="comInfo">
                      <span class="fontHover">{{
                        item.com_external_name
                      }}</span>
                      <p>
                        <span
                          >{{ item.com_nature }}/{{ item.com_province }}</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="hotcomPost">
                    <span
                      style="color:#FF6F24;font-size:17px;font-weight: bold;"
                    >
                      {{ item.par_job }}
                    </span>
                    <span style="color: #999">个热招职位</span>
                    <img :src="hotPng" alt="" style="float: right;" />
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <div style="padding:20px" class="text-center">
          <el-button
            class="radius-25 bigBtn"
            type="primary"
            v-prevent-repeat-click
            @click="goComList"
          >
            查看更多
          </el-button>
        </div>
      </div>
      <!-- 最新企业 -->
      <div class="hotComBox">
        <el-tabs v-model="hotComActive">
          <el-tab-pane label="最新企业" name="first">
            <el-row :gutter="24" class="hotComList">
              <el-col
                :span="8"
                v-for="item in newComList"
                :key="item.id"
                class="margin-bottom-16"
              >
                <div class="hotComItem" @click="goComDetail(item)">
                  <div>
                    <img class="hotcomImg" :src="item.com_url" alt="Logo" />
                    <div class="comInfo">
                      <span class="fontHover">{{
                        item.com_external_name
                      }}</span>
                      <p>
                        <span>{{ item.com_province }}/{{ item.com_city }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="hotcomPost">
                    <span
                      style="color:#FF6F24;font-size:17px;font-weight: bold;"
                    >
                      {{
                        parseInt(item.par_job_count) +
                          parseInt(item.full_job_count) +
                          parseInt(item.resume_count)
                      }}
                    </span>
                    <span style="color: #999">个热招职位</span>
                    <img :src="hotPng" alt="" style="float: right;" />
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <div style="padding:20px" class="text-center">
          <el-button
            class="radius-25 bigBtn"
            type="primary"
            v-prevent-repeat-click
            @click="goComList"
          >
            查看更多
          </el-button>
        </div>
      </div>
      <!-- 排行榜 -->
      <div>
        <el-tabs v-model="hotComActive">
          <el-tab-pane label="猎头榜单" name="first">
            <el-row :gutter="16" class="margin-bottom-16">
              <!-- 活跃榜 -->
              <el-col :span="12" class="rankBox">
                <div class="rankBg">
                  <!-- 榜首 -->
                  <div class="bangshou border-b-l">
                    <h3 class="text-center">
                      <span>活跃榜</span>
                    </h3>
                    <p class="text-center crownBox">
                      <img :src="crownIcon" alt="" class="crown" />
                      <img
                        class="crownUser"
                        :src="liveFirst.user_portrait"
                        alt=""
                      />
                      <img :src="rankIcon" alt="" class="rankimg" />
                    </p>
                    <div class="crownInfo">
                      <span class="name">
                        {{ liveFirst.user_name }}
                      </span>
                      <span class="time">
                        {{ liveFirst.login_times }}
                      </span>
                    </div>
                    <div class="crownInfo">
                      <span class="power">
                        活跃度：
                        <span>{{ liveFirst.activity_rate }}%</span>
                      </span>
                      <span class="time"
                        >在网：{{ liveFirst.regist_times }}个月</span
                      >
                    </div>
                  </div>
                  <!-- 榜二 -->
                  <div
                    class="rank border-b-l"
                    v-for="(item, index) in liveList"
                    :key="index"
                  >
                    <div class="rankItem">
                      <span class="rankNumBox">
                        <img class="rankNumBg" :src="medalIcon" alt="" />
                        <span class="rankNum">{{ index + 2 }}</span>
                      </span>
                      <div class="rankInfo">
                        <img
                          class="rankitemimg"
                          :src="item.user_portrait"
                          alt=""
                        />
                        <span class="itemname">
                          {{ item.user_name }}
                        </span>
                        <span class="itemtime">
                          {{ item.login_times }}
                        </span>
                        <span class="itemporwen">
                          活跃度：
                          <span>{{ item.activity_rate }}%</span>
                        </span>
                        <span class="itemtime2"
                          >在网：{{ item.regist_times }}个月</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <!-- 专业榜 -->
              <el-col :span="12" class="rankBox">
                <div class="rankBg">
                  <!-- 榜首 -->
                  <div class="bangshou border-b-l">
                    <h3 class="text-center">
                      <span>专业榜</span>
                    </h3>
                    <p class="text-center crownBox">
                      <img :src="crownIcon" alt="" class="crown" />
                      <img
                        class="crownUser"
                        :src="majorFirst.user_portrait"
                        alt=""
                      />
                      <img :src="rankIcon" alt="" class="rankimg" />
                    </p>
                    <div class="crownInfo">
                      <span class="name">
                        {{ majorFirst.user_name }}
                      </span>
                      <span class="time">
                        {{ majorFirst.login_times }}
                      </span>
                    </div>
                    <div class="crownInfo">
                      <span class="power">
                        专业能力：
                        <span>{{ majorFirst.professionalism }}</span>
                      </span>
                      <span class="time"
                        >在网：{{ majorFirst.regist_times }}个月</span
                      >
                    </div>
                  </div>
                  <!-- 榜二 -->
                  <div
                    class="rank border-b-l"
                    v-for="(item, index) in majorList"
                    :key="index"
                  >
                    <div class="rankItem">
                      <span class="rankNumBox">
                        <img class="rankNumBg" :src="medalIcon" alt="" />
                        <span class="rankNum">{{ index + 2 }}</span>
                      </span>
                      <div class="rankInfo">
                        <img
                          class="rankitemimg"
                          :src="item.user_portrait"
                          alt=""
                        />
                        <!-- <img class="rankitemimg" :src="item.user_portrait" alt="" /> -->
                        <span class="itemname">
                          {{ item.user_name }}
                        </span>
                        <span class="itemtime">
                          {{ item.login_times }}
                        </span>
                        <span class="itemporwen">
                          专业能力：
                          <span>{{ item.professionalism }}</span>
                        </span>
                        <span class="itemtime2"
                          >在网：{{ item.regist_times }}个月</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 建筑资讯 -->
      <div>
        <div class="zixunTitleBox">
          <span class="line"></span>
          <span class="zixunTitle">精选文章</span>
          <span class="line"></span>
        </div>
        <el-tabs v-model="zixunActive" @tab-click="changetab">
          <el-tab-pane label="热点" name="first"></el-tab-pane>
          <el-tab-pane label="考试资讯" name="second"></el-tab-pane>
          <el-tab-pane label="人才指南" name="third"></el-tab-pane>
          <el-tab-pane label="猎头指导" name="four"></el-tab-pane>
          <el-tab-pane label="薪酬福利" name="five"></el-tab-pane>
          <el-table :data="zixunData" class="partTimeTable">
            <el-table-column width="170px">
              <template slot-scope="scope">
                <img
                  :src="
                    scope.row.content.match(/http.*?(?:jpg|\/>)/gi) != null
                      ? scope.row.content.match(/http.*?(?:jpg|\/>)/gi)
                      : zixunImg
                  "
                  style="width:170px"
                />
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <div>
                  <span
                    class="zixuxtableTitle fontHover"
                    @click="gozixun(scope.row.id)"
                  >
                    {{ scope.row.title }}
                  </span>
                  <span style="float: right;">
                    {{ scope.row.push_time }}
                  </span>
                </div>
                <div>
                  <div class="zixunContent" v-html="scope.row.content"></div>
                </div>
                <div>发布人：小奕</div>
                <!-- <div>发布人：{{ scope.row.push_name }}</div> -->
              </template>
            </el-table-column>
            <div slot="empty" class="empty">
              <div>
                <img :src="noDate" />
              </div>
              <span>暂无数据</span>
            </div>
          </el-table>
        </el-tabs>
      </div>
    </section>
  </div>
</template>
<script>
import workNav from "@/view/home/workNav.vue";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      searchIcon: require("@/assets/icon/search-icon.png"),
      topbanner: require("@/assets/img/topbanner01.png"),
      hotPng: require("@/assets/icon/hot.png"),
      commendIcon: require("@/assets/icon/recommend.png"),
      crownIcon: require("@/assets/icon/crown.png"),
      rankIcon: require("@/assets/icon/rank.png"),
      medalIcon: require("@/assets/icon/medal.png"),
      noDate: require("@/assets/img/noDate.png"),
      zixunImg: require("@/assets/img/zixun.png"),
      zizhiImg: require("@/assets/img/zizhiimg.png"),
      searchCer: "",
      hotComActive: "first",
      hotzizhiList: [],
      hotComList: [],
      newComList: [],
      hotWorkList: [],
      newWorkList: [],
      liveFirst: {},
      liveList: [],
      majorFirst: {},
      majorList: [],
      selectWorkType: "找兼职",
      zixunActive: "first",
      zixunData: [],
      searchInfo: {},
    };
  },
  components: {
    workNav,
  },
  computed: {
    ...mapState(["comId"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    ...mapMutations(["setcomRealeInfo", "setcomStaffInfo", "setcomReale"]),
    // 热门职位
    getHotWork() {
      this.$http
        .post("index/home/hotjob", { page: 1, limit: 12 })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.hotWorkList = res.data.resultData;
          }
        });
    },
    // 最新职位
    getnewWork() {
      this.$http
        .post("index/parjob/getlist", { page: 1, limit: 12 })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.newWorkList = res.data.resultData.list;
          }
        });
    },
    // 获取资质公司
    getHotZizhi() {
      this.$http
        .post("index/home/getqualifications", { page: 1, limit: 12 })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.hotzizhiList = res.data.resultData.list;
          }
        });
    },
    // 获取热门公司
    getHotCom() {
      this.$http
        .post("index/home/hotcompany", { page: 1, limit: 12 })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.hotComList = res.data.resultData;
          }
        });
    },
    // 获取最新公司
    getNewCom() {
      this.$http
        .post("index/company/getlist", { page: 1, limit: 12 })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.newComList = res.data.resultData.list;
          }
        });
    },
    goComList() {
      let comtype = "full";
      this.$router.push({
        name: "comList",
        params: { comtype: comtype },
      });
    },
    goaptList() {
      this.$router.push({ path: "aptitudeIndex" });
    },
    // 活跃榜
    liveOfActive() {
      this.$http
        .post("index/home/activerank", { page: 1, limit: 10 })
        .then((res) => {
          if (res.data.resultCode === 1) {
            let data = res.data.resultData;
            this.liveFirst = data.shift();
            this.liveList = data;
            // this.liveList = data.slice(1);
          }
        });
    },
    // 专业榜
    majorRank() {
      this.$http
        .post("index/home/absorbedrank", { page: 1, limit: 10 })
        .then((res) => {
          if (res.data.resultCode === 1) {
            let data = res.data.resultData;
            this.majorFirst = data.shift();
            this.majorList = data;
            // this.liveList = data.slice(1);
          }
        });
    },
    /**
     * @description: 跳转兼职职位详情
     * @param  {*}
     * @return {*}
     */
    gopartTime(item) {
      let routeUrl = this.$router.resolve({
        name: "partTimeInfo",
        query: { id: item.id, cer: item.j_certificate, major: item.j_major },
      });
      window.open(routeUrl.href, "_blank");
    },
    /**
     * @description: 跳转兼职
     * @param  {*}cer:证书
     * @param  {*}major: 证书专业
     */

    goPartTimePage(cer, major) {
      this.$router.push({
        name: "partTimeIndex",
        params: { cer: cer, major: major },
      });
    },
    // 前往兼职列表
    goPartTimeIndex() {
      let routeUrl = this.$router.resolve({
        name: "partTimeIndex",
      });
      window.open(routeUrl.href, "_blank");
    },
    // 搜索
    searchPost() {
      if (this.selectWorkType == "找兼职") {
        this.$router.push({
          name: "partTimeIndex",
          params: { cer: this.searchCer, major: "" },
        });
      } else if (this.selectWorkType == "找全职") {
        this.$router.push({
          name: "fullTimeIndex",
          params: { cer: this.searchCer, major: "" },
        });
      }
    },
    // 跳转公司详情
    goComDetail(item) {
      let routeUrl = this.$router.resolve({
        name: "comDetail",
        query: { comid: item.id },
      });
      window.open(routeUrl.href, "_blank");
    },
    goComDetail2(item) {
      let routeUrl = this.$router.resolve({
        name: "comDetail",
        query: { comid: item.j_company_id },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 建讯
    getzixunData() {
      this.searchInfo.page = 1;
      this.searchInfo.limit = 3;
      this.$http.post("index/home/getinfo", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.zixunData = res.data.resultData.list;
        }
      });
    },
    changetab(e) {
      switch (e.name) {
        case "first":
          this.searchInfo.category = "1";
          break;

        case "second":
          this.searchInfo.category = "2";
          break;

        case "third":
          this.searchInfo.category = "3";
          break;

        case "four":
          this.searchInfo.category = "4";
          break;

        case "five":
          this.searchInfo.category = "5";
          break;
      }
      this.getzixunData();
    },
    gozixun(id) {
      let routeUrl = this.$router.resolve({
        name: "consultingDetail",
        query: { id: id },
      });
      window.open(routeUrl.href, "_blank");
    },
    gozizhiDetail(id) {
      let routeUrl = this.$router.resolve({
        name: "aptitudeDetail",
        query: { id: id },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 兼职投递简历
    deliver(item) {
      // 判断登录者 身份
      if (this.userInfo.u_identity == undefined) {
        this.$message.warning("您还没有登录，2秒后跳转登录页面！");
        setTimeout(() => {
          this.$router.push("/Login");
        }, 2000);
        return;
      }
      if (this.userInfo.u_identity != 1) {
        this.$message.warning("只有人才才能投递简历！");
        return;
      }
      // 投递信息
      let info = {
        publish_uid: item.j_uid,
        com_type: item.j_type,
        com_id: item.j_company_id,
        position_id: item.id,
        position_name: item.j_certificate,
        position_type: 1,
      };
      this.$http.post("talent/index/delivery", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$notify({
            type: "success",
            title: "投递成功",
            message: "您已投递成功，请耐心等待招聘人员与您联系！",
          });
        }
      });
    },
    // 发布职位
    postJobBtn() {
      if (JSON.stringify(this.userInfo) == "{}") {
        this.$message.warning("您还没有登录，现在前往登录界面！");
        setTimeout(() => {
          this.$router.push("/Login");
        }, 1600);
        return;
      }
      if (this.userInfo.u_identity == 1) {
        this.$message.warning("您是人才角色，不能发布职位！");
        return;
      }
      this.$http
        .post("index/recharge/recharge", { id: this.userInfo.uid })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.vipInfo = res.data.resultData;
            if (this.vipInfo.u_surplus_push < 1) {
              this.$message.warning(
                "您的发布次数已用完，您可以前往会员服务购买会员！"
              );
            } else {
              if (this.comId != 0 || this.userInfo.u_company != 0) {
                this.$router.push("/comPage/postType");
              } else {
                this.$message.success(
                  "您还未认证企业，现在前往完善认证企业信息！"
                );
                setTimeout(() => {
                  this.$router.push("comrealSel");
                }, 1200);
              }
            }
          }
        });
      this.getRealeInfo();
    },
    getRealeInfo() {
      // 企业认证信息
      if (this.comId == 0) {
        this.$http.post("company/index/getcompanyinfo").then((res) => {
          if (res.data.resultCode === 1) {
            this.setcomRealeInfo({
              comRealeInfo: res.data.resultData,
            });
          }
        });
      } else {
        this.$http
          .post("company/index/getcompanyinfo", { u_company: this.comId })
          .then((res) => {
            if (res.data.resultCode === 1) {
              this.setcomRealeInfo({
                comRealeInfo: res.data.resultData,
              });
            }
          });
      }
      // 企业员工信息
      this.$http.post("company/user/getpersonal").then((res) => {
        if (res.data.resultCode === 1) {
          this.setcomStaffInfo({
            comStaffInfo: res.data.resultData,
          });
        }
      });
    },
  },
  mounted() {
    this.getHotWork();
    this.getnewWork();
    this.getHotZizhi();
    this.getHotCom();
    this.getNewCom();
    this.liveOfActive();
    this.majorRank();
    this.searchInfo.category = 1;
    this.getzixunData();
  },
};
</script>
<style scoped>
.searchBox {
  margin: 0px 100px;
  padding: 14px 0;
}
.searchInput {
  width: calc(100% - 136px);
  display: inline-block;
}
/deep/.searchBox .input_select {
  width: 100px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
/* /deep/.searchBox .el-input-group__append {
  background-color: #0070fa;
  border-color: #0070fa;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #fff;
  cursor: pointer;
} */
.ziZhiTitle {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.zizhiImg {
  height: 50px;
  padding: 10px 15px;
}
.hotcer .el-button--text {
  color: #333;
}
.hotcer .el-button--text:hover {
  color: #2573f1;
}
.el-input-group__append img {
  width: 20px;
}
.hotWorkList,
.hotComList {
  padding: 20px 14px;
}
.hotWorkItem {
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  height: 165px;
}
.hotWorkItem:hover {
  box-shadow: 0px 5px 14px 6px rgba(173, 193, 213, 0.5);
}
.hotWorkItem .commendIcon {
  position: absolute;
  height: 16px;
}
.hotWorkItem .commendFont {
  position: absolute;
  z-index: 111;
  font-size: 10px;
  color: #fff;
  padding-left: 10px;
  margin-top: 1px;
  font-weight: bold;
}
.hotComItem {
  background: #ffffff;
  border-radius: 4px;
  height: 130px;
}
.hotComItem:hover {
  box-shadow: 0px 5px 14px 6px rgba(173, 193, 213, 0.5);
}
.hotcomImg {
  width: 40px;
  height: 40px;
  padding: 15px 20px;
}
.comInfo {
  width: calc(100% - 100px);
  display: inline-block;
}
.comInfo > span {
  font-size: 17px;
  font-weight: 800;
  color: #333;
  line-height: 69px;
  position: relative;
  top: -3px;
}
.comInfo p {
  position: relative;
  top: -20px;
  color: #999;
  font-size: 12px;
}
.hotcomPost {
  border-top: 1px solid #e8e8e8;
  padding: 12px 20px;
}
.hotworkPost {
  border-top: 1px solid #e8e8e8;
}
.hotworkComName {
  width: 190px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.workInfo {
  padding: 20px;
}
.workName {
  font-size: 17px;
  font-weight: 800;
  color: #333;
}
.workTime {
  float: right;
}
.workComInfo .wageNum {
  color: #ff6f24;
  font-size: 16px;
  margin-right: 10px;
}
.workCom {
  width: calc(100% - 100px);
  display: inline-block;
}
.workCom > span {
  font-size: 17px;
  font-weight: 800;
  color: #333;
  position: relative;
  top: -20px;
}
.workCom > p {
  position: relative;
  top: -15px;
}
.rankBox {
}
.rankBg {
  background: #fff;
  /* max-height: 1116px; */
}
.rankBox h3 span {
  font-size: 17px;
  color: #333;
  border-bottom: 4px solid #2674fc;
  display: inline-block;
  line-height: 2;
}
.bangshou {
  padding: 12px 0;
}
.crownBox {
  position: relative;
}
.crown {
  position: absolute;
  top: 0px;
  left: 270px;
}
.crownUser {
  width: 60px;
  height: 60px;
  border: 3px solid #fbc354;
  border-radius: 50%;
  margin: 30px 0 60px;
}
.rankimg {
  position: absolute;
  top: 90px;
  left: 225px;
}
.crownInfo {
  text-align: center;
  padding-bottom: 6px;
}
.crownInfo .name {
  font-size: 17px;
  font-weight: bolder;
  margin-right: 8px;
}
.crownInfo .time {
  color: #999;
}
.crownInfo .power {
  color: #999;
  margin-right: 20px;
}
.crownInfo .power span {
  color: #ff0000;
  font-weight: bolder;
}
.rankItem {
  position: relative;
  padding: 16px;
}
.rankNumBox {
  position: relative;
  text-align: center;
  display: inline-block;
  width: 66px;
  height: 55px;
}
.rankNumBg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.rankNum {
  color: #ac710c;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  z-index: 1;
  top: 20px;
}
.rankInfo {
  position: absolute;
  top: 35px;
  left: 100px;
  display: flex;
  align-items: center;
}
.rankInfo .rankitemimg {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}
.rankInfo .itemname {
  width: 120px;
  font-size: 17px;
  font-weight: bold;
}
.rankInfo .itemtime {
  width: 115px;
  color: #999;
}
.rankInfo .itemporwen {
  width: 105px;
  color: #999;
}
.rankInfo .itemporwen span {
  color: #ff0000;
  font-weight: bolder;
}
.rankInfo .itemtime2 {
  width: 100px;
  text-align: right;
  float: right;
  color: #999;
}
.touBtn:hover {
  border-color: #2573f1;
  background-color: #2573f1;
  color: #fff;
}
.zixunTitleBox {
  text-align: center;
}
.zixunTitleBox .zixunTitle {
  font-size: 20px;
  padding: 40px 30px;
  display: inline-block;
  font-family: "heiti";
  font-weight: bold;
}
.zixunTitleBox .line {
  width: 120px;
  height: 1px;
  display: inline-block;
  position: relative;
  top: -6px;
  background: #999;
}
.zixuxtableTitle {
  font-size: 17px;
  font-weight: bold;
  color: #333;
}
.zixunContent {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
}
/deep/ .el-input-group__prepend {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
/deep/ .el-input-group__prepend .el-input__inner {
  padding-left: 23px;
}
/deep/ .el-input-group__append,
/deep/ .el-input-group__append .el-button {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
/deep/ .partTimeTable .el-table__header-wrapper {
  height: 0;
}
</style>
