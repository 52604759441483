<!--
 * @Description: 首页职位导航+banner
 * @Author: huacong
 * @Date: 2021-05-06 09:15:13
 * @LastEditTime: 2021-12-22 16:00:48
 * @LastEditors: huacong
-->
<template>
  <div class="margin-bottom-16">
    <el-row :gutter="16">
      <el-col :span="9">
        <div class="whiteBg">
          <!-- 一级建造师 -->
          <el-popover placement="right-start" trigger="hover">
            <div style="width: 500px">
              <p class="titletop fontBlod">
                一1级建造师
              </p>
              <div class="margin-bottom-1">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer01"
                    :key="item.id"
                    @click="goPartTime('一级建造师', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
            </div>
            <div slot="reference" class="navList">
              <p>
                <el-button
                  type="text"
                  class="cerBtn fontBlod"
                  @click="goPartTime('一级建造师', '')"
                >
                  一级建造师
                </el-button>
              </p>
              <div>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('一级建造师', '房建工程')"
                >
                  房建工程
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('一级建造师', '机电安装')"
                >
                  机电安装
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('一级建造师', '市政公用')"
                >
                  市政公用
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('一级建造师', '公路工程')"
                >
                  公路工程
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('一级建造师', '水利水电')"
                >
                  水利水电
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('一级建造师', '装饰装修')"
                >
                  装饰装修
                </el-button>
              </div>
            </div>
          </el-popover>
          <!-- 二级建造师 -->
          <el-popover placement="right-start" trigger="hover">
            <div style="width: 500px">
              <p class="fontBlod titletop">二级建造师</p>
              <div class="margin-bottom-1">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer02"
                    :key="item.id"
                    @click="goPartTime('二级建造师', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
            </div>
            <div slot="reference" class="navList">
              <p>
                <el-button
                  type="text"
                  class="cerBtn fontBlod"
                  @click="goPartTime('二级建造师', '')"
                >
                  二级建造师
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('二级建造师', '房建工程')"
                >
                  房建工程
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('二级建造师', '机电安装')"
                >
                  机电安装
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('二级建造师', '市政公用')"
                >
                  市政公用
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('二级建造师', '公路工程')"
                >
                  公路工程
                </el-button>
              </p>
            </div>
          </el-popover>
          <!-- 注册建筑师 注册结构师 公用设备工程师 -->
          <el-popover placement="right-start" trigger="hover">
            <div style="width: 500px">
              <p class="fontBlod titletop">注册建筑师</p>
              <div class="margin-bottom-10">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer03"
                    :key="item.id"
                    @click="goPartTime('注册建筑师', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
              <p class="fontBlod titletop">注册结构师</p>
              <div class="margin-bottom-10">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer04"
                    :key="item.id"
                    @click="goPartTime('注册结构师', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
              <p class="fontBlod titletop">公用设备工程师</p>
              <div class="margin-bottom-1">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer05"
                    :key="item.id"
                    @click="goPartTime('公用设备工程师', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
            </div>
            <div slot="reference" class="navList">
              <p>
                <el-button
                  type="text"
                  class="cerBtn fontBlod"
                  @click="goPartTime('注册建筑师', '')"
                >
                  注册建筑师
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn fontBlod"
                  @click="goPartTime('注册结构师', '')"
                >
                  注册结构师
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn fontBlod"
                  @click="goPartTime('公用设备工程师', '')"
                >
                  公用设备工程师
                </el-button>
              </p>
            </div>
          </el-popover>
          <!-- 注册监理工程师 注册造价工程师 注册咨询工程师 -->
          <el-popover placement="right-start" trigger="hover">
            <div style="width: 500px">
              <p class="fontBlod titletop">注册监理工程师</p>
              <div class="margin-bottom-10">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer06"
                    :key="item.id"
                    @click="goPartTime('注册监理工程师', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
              <p class="fontBlod titletop">注册造价工程师</p>
              <div class="margin-bottom-10">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer07"
                    :key="item.id"
                    @click="goPartTime('注册造价工程师', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
              <p class="fontBlod titletop">注册咨询工程师</p>
              <div class="margin-bottom-1">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer08"
                    :key="item.id"
                    @click="goPartTime('注册咨询工程师', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
            </div>
            <div slot="reference" class="navList">
              <p>
                <el-button
                  type="text"
                  class="cerBtn fontBlod"
                  @click="goPartTime('注册监理工程师', '')"
                >
                  注册监理工程师
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn fontBlod"
                  @click="goPartTime('注册造价工程师', '')"
                >
                  注册造价工程师
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn fontBlod no-margin-right"
                  @click="goPartTime('注册咨询工程师', '')"
                >
                  注册咨询工程师
                </el-button>
              </p>
            </div>
          </el-popover>
          <!-- 职称证书 -->
          <el-popover placement="right-start" trigger="hover">
            <div style="width: 600px">
              <p class="fontBlod titletop">职称证书</p>
              <div class="margin-bottom-1">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer09"
                    :key="item.id"
                    @click="goPartTime('职称证书', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
            </div>
            <div slot="reference" class="navList">
              <p>
                <el-button
                  type="text"
                  class="cerBtn fontBlod"
                  @click="goPartTime('职称证书', '')"
                >
                  职称证书
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('职称证书', '建筑工程')"
                >
                  建筑工程
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('职称证书', '结构')"
                >
                  结构
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('职称证书', '民建工程')"
                >
                  民建工程
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('职称证书', '土木工程')"
                >
                  土木工程
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('职称证书', '暖通')"
                >
                  暖通
                </el-button>
              </p>
            </div>
          </el-popover>
          <!-- 技工证 其他证书 -->
          <el-popover placement="right-start" trigger="hover">
            <div style="width: 500px">
              <p class="fontBlod titletop">技工证</p>
              <div class="margin-bottom-10">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer10"
                    :key="item.id"
                    @click="goPartTime('技工证', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
              <p class="fontBlod titletop">其他证书</p>
              <div class="margin-bottom-1">
                <p>
                  <el-button
                    type="text"
                    class="cerBtn"
                    v-for="item in cer11"
                    :key="item.id"
                    @click="goPartTime('其他证书', item.certificate)"
                  >
                    {{ item.certificate }}
                  </el-button>
                </p>
              </div>
            </div>
            <div slot="reference" class="navList">
              <p>
                <el-button
                  type="text"
                  class="cerBtn fontBlod"
                  @click="goPartTime('技工证', '')"
                >
                  技工证
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('技工证', '电工')"
                >
                  电工
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('技工证', '焊工')"
                >
                  焊工
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('技工证', '防水工')"
                >
                  防水工
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('技工证', '油漆工')"
                >
                  油漆工
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn"
                  @click="goPartTime('技工证', '钢筋工')"
                >
                  钢筋工
                </el-button>
                <el-button
                  type="text"
                  class="cerBtn fontBlod"
                  @click="goPartTime('其他证书', '')"
                >
                  其他证书
                </el-button>
              </p>
            </div>
          </el-popover>
        </div>
      </el-col>
      <el-col :span="15">
        <el-carousel height="390px" :interval="5000">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <el-image :src="item"></el-image>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      banner01: require("@/assets/img/banner01.png"),
      banner02: require("@/assets/img/banner02.png"),
      bannerList: [
        require("@/assets/img/banner05.png"),
        require("@/assets/img/banner01.png"),
        require("@/assets/img/banner02.png"),
        require("@/assets/img/banner03.png"),
        require("@/assets/img/banner04.png"),
      ],
      cer01: [],
      cer02: [],
      cer03: [],
      cer04: [],
      cer05: [],
      cer06: [],
      cer07: [],
      cer08: [],
      cer09: [],
      cer10: [],
      cer11: [],
    };
  },
  methods: {
    /**
     * @description: 获取证书名称下面的专业
     * @param  {*}
     * @return {*}
     */
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        if (res.data.resultCode === 1) {
          let data = res.data.resultData;
          data.forEach((element) => {
            if (element.certificate == "一级建造师") {
              this.cer01 = element.child;
            }
            if (element.certificate == "二级建造师") {
              this.cer02 = element.child;
            }
            if (element.certificate == "注册建筑师") {
              this.cer03 = element.child;
            }
            if (element.certificate == "注册结构师") {
              this.cer04 = element.child;
            }
            if (element.certificate == "公用设备工程师") {
              this.cer05 = element.child;
            }
            if (element.certificate == "注册监理工程师") {
              this.cer06 = element.child;
            }
            if (element.certificate == "注册造价工程师") {
              this.cer07 = element.child;
            }
            if (element.certificate == "注册咨询工程师") {
              this.cer08 = element.child;
            }
            if (element.certificate == "职称证书") {
              this.cer09 = element.child;
            }
            if (element.certificate == "技工证") {
              this.cer10 = element.child;
            }
            if (element.certificate == "其他证书") {
              this.cer11 = element.child;
            }
          });
        }
      });
    },
    /**
     * @description: 跳转兼职
     * @param  {*}cer:证书
     * @param  {*}major: 证书专业
     */

    goPartTime(cer, major) {
      this.$router.push({
        name: "partTimeIndex",
        params: { cer: cer, major: major },
      });
    },
  },
  mounted() {
    this.getCerList();
  },
};
</script>
<style scoped>
.whiteBg {
  background: #fff;
}
.cerBtn {
  color: #333;
  padding: 0;
  margin: 10px 0;
  margin-right: 10px;
}
.fontBlod {
  font-size: 17px;
  font-weight: bold;
  margin-right: 25px;
}
.no-margin-right {
  margin-right: 0;
}
.cerBtn:hover {
  color: #2573f1;
}
.navList {
  padding: 10px;
}
.titletop {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  color: #2573f1;
}
</style>
